<template>
  <getecma-modal
    ref="modal"
    title="Editar coleta"
    v-bind="$attrs"
    v-on="$listeners">
    <template #content>
      <div>
          <getecma-header size="sm">Excluir Coleta</getecma-header>
      </div>
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <el-switch v-model="deleteCollect" active-text="Sim" inactive-text="Não" />
        </div>
      </div>
      <div v-if="!deleteCollect">
        <div class="row mt--xl">
          <div class="form-field col-18 col-lg-9">
            <div>
                <getecma-header size="sm"> Alterar Veículo</getecma-header>
            </div>
            <getecma-select
              name="veículo*"
              placeholder="Selecione um novo veículo"
              :options="vehicles"
              @on-change="onVehicleChange">
            </getecma-select>
          </div>
        </div>
      </div>
      <div v-if="notEmployees && !deleteCollect">
        <div class="row mt--xl">
          <div class="form-field col-18 col-lg-9">
            <div>
                <getecma-header size="sm"> Motorista</getecma-header>
            </div>
            <getecma-select
              name="motista"
              placeholder="Selecione um motorista"
              :options="employees"
              @on-change="onDriverChange">
            </getecma-select>
          </div>
        </div>
        <div class="row mt--xl">
          <div class="form-field col-18 col-lg-9">
            <div>
                <getecma-header size="sm"> Ajudante</getecma-header>
            </div>
            <getecma-select
              name="motista"
              placeholder="Selecione um ajudante"
              :options="employees"
              @on-change="onHelperChange">
            </getecma-select>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <getecma-button
        class="p--md"
        size="sm"
        :round="false"
        bg-color="success"
        @click="confirm()">
        Salvar
      </getecma-button>
    </template>
  </getecma-modal>
</template>

<script>
import { fetchEmployees } from '@/modules/employee/employee.service';
import { fetchVehicles } from '@/modules/vehicle/vehicle.service';
import { fetchEmployeesByCollect } from '@/modules/home/home.service';
import { toastError } from '@/services/toastService';
import { EMPLOYEES_ROLES } from '@/modules/employee/employee.constants';

export default {
  name: 'GetecmaEditCollectModal',
  inject: ['collectVm'],
  data() {
    return {
      collect: this.collectVm.collect,
      employees: [],
      vehicles: [],
      notEmployees: true,
      deleteCollect: false,
    };
  },
  mounted() {
    this.fetchVehicles();
    this.fetchEmployees();
    this.fetchEmployeesByCollect();
  },
  methods: {
    fetchEmployeesByCollect() {
      fetchEmployeesByCollect(this.collect.id)
        .then(data => {
          if (data.rows.length > 0) {
            this.notEmployees = false;
          }
        })
        .catch(() => toastError('Erro ao obter funcionários por coleção'));
    },
    async fetchVehicles() {
      try {
        const params = { limit: 'all', page: 1, search: '' };
        const response = await fetchVehicles(params);
        this.vehicles = response.rows.map(vehicle => ({
          key: vehicle.id,
          value: vehicle.license_plate,
        }));
      } catch (error) {
        console.error('Erro ao buscar os veículos:', error);
      }
    },
    async fetchEmployees() {
      try {
        const params = { limit: 'all', page: 1, search: '' };
        const response = await fetchEmployees(params);
        this.employees = response.rows.map(employee => ({
          key: employee.id,
          value: employee.name,
        }));
      } catch (error) {
        console.error('Erro ao buscar os funcionários:', error);
      }
    },
    onVehicleChange(vehicle) {
      this.collect.vehicle_id = vehicle.key;
    },
    onDriverChange(employee) {
      this.collect.employees.push({ employee_id: employee.key, function: EMPLOYEES_ROLES.DRIVER });
    },
    onHelperChange(employee) {
      this.collect.employees.push({ employee_id: employee.key, function: EMPLOYEES_ROLES.HELPER });
    },
    async confirm() {
      if (this.deleteCollect) {
        this.collect.delete = true;
      }
      this.$emit('onEditCollect');
      this.$refs.modal.close();
    },
  },
};
</script>

<style lang='css'>
  .el-input__icon {
    display: none;
  }
  .fc-daygrid-event {
    overflow: hidden;
  }
</style>
