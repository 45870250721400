import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from '@/router/routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes,
  linkActiveClass: 'active',
});

router.beforeEach((to, from, next) => {
  const searchQueryCustomer = localStorage.getItem('searchQueryCustomer');
  localStorage.setItem('searchQueryCustomer', '');
  window.addEventListener('popstate', () => {
    localStorage.setItem('searchQueryCustomer', searchQueryCustomer);
  });
  next();
});

export default router;
